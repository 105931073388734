<template>
  <div>

    <!-- content -->

    <b-row
      v-if="trainingHistoryData"
      class="justify-content-md-center"
    >
      <!-- blogs -->

      <b-col md="6">
        <b-card
          v-if="trainingHistoryData.is_complete==true"

          :title="$t('Test Tamamlandı')"
        >

          <b-row class="text-center mx-0">
            <b-col
              cols="12"
              class="border-top border-right d-flex align-items-between flex-column py-0"
            >
              <b-card-text class="text-muted mb-0">
                {{ $t('Test Sonucu') }}
              </b-card-text>
              <b-badge
                v-if="trainingHistoryData.complete_stats.is_success"
                variant="success"
              >
                Başarılı
              </b-badge>
              <b-badge
                v-if="!trainingHistoryData.complete_stats.is_success"
                variant="danger"
              >
                Başarısız
              </b-badge>
            </b-col>
            <b-col
              cols="6"
              class="border-top border-right d-flex align-items-between flex-column py-0"
            >
              <b-card-text class="text-muted mb-0">
                {{ $t('Başarı Oranım') }}
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ trainingHistoryData.complete_stats.my_success_rate }}%
              </h3>
            </b-col>
            <b-col
              cols="6"
              class="border-top border-right d-flex align-items-between flex-column py-0"
            >
              <b-card-text class="text-muted mb-0">
                {{ $t('Gereken Minimum Başarı Oranı') }}
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ trainingHistoryData.complete_stats.required_complete_rate }}%
              </h3>
            </b-col>
            <b-col
              cols="6"
              class="border-top border-right d-flex align-items-between flex-column py-0"
            >
              <b-card-text class="text-muted mb-0">
                {{ $t('Toplam Soru') }}
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ trainingHistoryData.complete_stats.question_count }}
              </h3>
            </b-col>

            <b-col
              cols="6"
              class="border-top d-flex align-items-between flex-column py-0"
            >
              <b-card-text class="text-muted mb-0">
                {{ $t('Cevaplanan Soru') }}
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ trainingHistoryData.complete_stats.answer_count }}
              </h3>
            </b-col>
          </b-row>
          <b-button
            v-if="answer!=0"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="returnToTraining"
          >
            {{ $t('Eğitimlere Dön') }}
          </b-button>
        </b-card>
        <b-card v-if="questionData!=null && trainingHistoryData.is_complete==false">

          <b-row class="text-center mx-0">
            <b-col
              md="12"
              class="d-flex align-items-between flex-column py-0"
            >
              <b-card-text class="text-muted mb-0">
                {{ $t('Kalan Süre') }}
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ remainingTime }}
              </h3>
            </b-col>
            <b-col
              cols="6"
              class="border-top border-right d-flex align-items-between flex-column py-0"
            >
              <b-card-text class="text-muted mb-0">
                {{ $t('Toplam Soru') }}
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ questionData.complete_stats.question_count }}
              </h3>
            </b-col>

            <b-col
              cols="6"
              class="border-top d-flex align-items-between flex-column py-0"
            >
              <b-card-text class="text-muted mb-0">
                {{ $t('Cevaplanan Soru') }}
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ questionData.complete_stats.answer_count }}
              </h3>
            </b-col>
          </b-row>
        </b-card>
        <b-card

          v-if="questionData!=null&& trainingHistoryData.is_complete==false"

          :title="questionData.question"
        >

          <b-list-group>
            <b-list-group-item
              v-for="data in questionData.options"
              :key="data.id"
              :variant="data.variant"
              button
              @click="selectAnswer(data)"
            >
              {{ data.title }}
            </b-list-group-item>
          </b-list-group>
          <hr>
          <b-button
            v-if="true==false"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="nextQuestion"
          >
            {{ $t('Sonraki Soru') }}
          </b-button>
        </b-card>
      </b-col>
      <!--/ blogs -->

      <!-- Leave a Blog Comment -->

      <!--/ Leave a Blog Comment -->
    </b-row>
    <!--/ blogs -->

    <!--/ content -->

  </div>
</template>

<script>
import {

  BCard, BRow, BCol, BListGroup, BListGroupItem, BButton, BCardText, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import trainingStoreModule from '../trainingStoreModule'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BBadge,
    BCardText,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      answers: [],
      answer: 0,
      timeleft: 0,
      search_query: '',
      commentCheckmark: '',
      blogDetail: [],
      blogSidebar: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
    }
  },
  computed: {
    remainingTime() {
      if (this.trainingHistoryData == null) {
        return 'waiting..'
      }
      const totalSeconds = this.remainingTimeRaw
      const minutes = parseInt(totalSeconds / 60, 10)
      const seconds = parseInt(totalSeconds % 60, 10)
      return `${minutes}:${seconds}`
    },
  },
  methods: {
    returnToTraining() {
      router.push({ name: 'training-detail', params: { id: this.trainingHistoryData.training_id } })
    },
    nextQuestion() {
      this.getQuestion()
    },
    sendAnswers(item = null) {
      store.dispatch('app-training/selectQuestionAnswer', { training_history_id: this.trainingHistoryData.id, question_id: this.questionData.id, option_id: item.id })
        .then(response => {
          if (response.data.message === 'no_questions') {
            this.trainingHistoryData = response.data.data
          } else if (true === false) {
            // eslint-disable-next-line no-param-reassign
            // eslint-disable-next-line no-undef
            if (item != null) {
              // eslint-disable-next-line no-param-reassign
              // item.variant = 'success'
            }
          } else if (item != null) {
            // eslint-disable-next-line no-param-reassign
            // item.variant = 'danger'
          }
          this.nextQuestion()
        })
        .catch(error => {
          if (error.response.status === 404) {
            alert('Hata')
          } else if (error.response.data === 'time_end') {
            alert('Test Süresi Doldu')
          }
        })
    },
    selectAnswer(item) {
      // eslint-disable-next-line no-param-reassign
      this.answers = []
      this.questionData.options.forEach(val => {
        // eslint-disable-next-line no-param-reassign
        val.variant = ''
      })

      this.answer = item.id
      this.sendAnswers(item)
      if (this.answers.includes(item.id)) {
        // eslint-disable-next-line no-param-reassign
        item.variant = ''
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.answers.length; i++) {
          if (this.answers[i] === item.id) {
            this.answers.splice(i, 1)
          }
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        item.variant = 'secondary'
        this.answers.push(item.id)
      }
    },
  },

  setup() {
    const questionData = ref(null)
    const trainingHistoryData = ref(null)
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-training'
    const remainingTimeRaw = ref(null)

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, trainingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const getQuestion = () => {
      store.dispatch('app-training/fetchSingleQuestion', { training_history_id: router.currentRoute.params.training_history_id })
        .then(response => {
          if (response.data.message === 'no_questions') {
            trainingHistoryData.value = response.data.data
          } else {
            questionData.value = response.data.data
            questionData.value.options.forEach(val => {
            // eslint-disable-next-line no-param-reassign
              val.variant = ''
            })
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            questionData.value = undefined
          }
        })
    }
    store.dispatch('app-training/fetchSingleTrainingHistory', { id: router.currentRoute.params.training_history_id })
      .then(response => {
        trainingHistoryData.value = response.data.data
        remainingTimeRaw.value = trainingHistoryData.value.remaining_time
        getQuestion()
        setInterval(() => {
          if (remainingTimeRaw.value >= 0) {
            remainingTimeRaw.value -= 1
          }
        }, 1000)
      })
      .catch(error => {
        if (error.response.status === 404) {
          trainingHistoryData.value = undefined
        }
      })
    return {
      questionData,
      trainingHistoryData,
      getQuestion,
      remainingTimeRaw,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
